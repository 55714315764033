<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

// setup
type Props = {};
const {} = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "inline-flex flex-col",
};
const { aClass } = installAntlerComponent("menu__item-divider", {}, classes);
</script>

<template>
    <li :class="aClass()">
        <hr class="w-full border-t border-slate-200/50" />
    </li>
</template>
